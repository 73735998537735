unit Unit2;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls;

type
  TForm2 = class(TForm)
    WebButton2: TButton;
    WebLabel1: TLabel;
    WebLabel2: TLabel;
    frm2Edit: TEdit;
    procedure WebButton2Click(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  Form2: TForm2;

implementation

{$R *.dfm}

procedure TForm2.WebButton2Click(Sender: TObject);
begin
  Close;
end;

procedure TForm2.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebButton2 := TButton.Create(Self);
  frm2Edit := TEdit.Create(Self);

  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  frm2Edit.BeforeLoadDFMValues;
  try
    Name := 'Form2';
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -21;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 56;
    WebLabel1.Top := 28;
    WebLabel1.Width := 66;
    WebLabel1.Height := 25;
    WebLabel1.Caption := 'Form 2';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clRed;
    WebLabel1.Font.Height := -21;
    WebLabel1.Font.Name := 'Tahoma';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 16;
    WebLabel2.Top := 112;
    WebLabel2.Width := 75;
    WebLabel2.Height := 16;
    WebLabel2.Caption := 'Preset value:';
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -13;
    WebLabel2.Font.Name := 'Tahoma';
    WebLabel2.Font.Style := [];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.ParentFont := False;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 56;
    WebButton2.Top := 152;
    WebButton2.Width := 237;
    WebButton2.Height := 53;
    WebButton2.Caption := 'Close form';
    WebButton2.ChildOrder := 1;
    WebButton2.Font.Charset := DEFAULT_CHARSET;
    WebButton2.Font.Color := clWindowText;
    WebButton2.Font.Height := -11;
    WebButton2.Font.Name := 'Tahoma';
    WebButton2.Font.Style := [];
    WebButton2.HeightPercent := 100.000000000000000000;
    WebButton2.ParentFont := False;
    WebButton2.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    frm2Edit.SetParentComponent(Self);
    frm2Edit.Name := 'frm2Edit';
    frm2Edit.Left := 101;
    frm2Edit.Top := 111;
    frm2Edit.Width := 192;
    frm2Edit.Height := 22;
    frm2Edit.ChildOrder := 3;
    frm2Edit.Font.Charset := DEFAULT_CHARSET;
    frm2Edit.Font.Color := clWindowText;
    frm2Edit.Font.Height := -11;
    frm2Edit.Font.Name := 'Tahoma';
    frm2Edit.Font.Style := [];
    frm2Edit.HeightPercent := 100.000000000000000000;
    frm2Edit.ParentFont := False;
    frm2Edit.WidthPercent := 100.000000000000000000;
  finally
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    frm2Edit.AfterLoadDFMValues;
  end;
end;

end.