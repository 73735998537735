unit Unit1;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  WEBLib.ExtCtrls, XData.Web.Client, XData.Web.Connection, Data.DB, WEBLib.DB,
  XData.Web.JsonDataset, XData.Web.Dataset, Vcl.Grids, WEBLib.DBCtrls,
  WEBLib.Grids, Types, WEBLib.XLSX;

type
  TForm1 = class(TForm)
    wbpnl1: TPanel;
    WebButton10: TButton;
    lbl1: TLabel;
    mmo1: TMemo;
    WebButton1: TButton;
    WebCheckBox1: TCheckBox;
    WebEdit1: TEdit;
    WebPanel1: TPanel;
    WebLabel2: TLabel;
    WebLabel1: TLabel;
    XDataWebConnection1: TXDataWebConnection;
    WebButton2: TButton;
    XDataWebClient1: TXDataWebClient;
    WebButton3: TButton;
    XDataWebDataSet1: TXDataWebDataSet;
    WebDataSource1: TDataSource;
    WebButton4: TButton;
    WebEdit2: TEdit;
    WebEdit3: TEdit;
    WebButton5: TButton;
    WebButton6: TButton;
    XDataWebDataSet1ID_TYPE_OF_RADIO: TIntegerField;
    XDataWebDataSet1DESCRIPTION: TStringField;
    WebStringGrid1: TStringGrid;
    WebButton7: TButton;
    WebXLSX1: TXLSX;
    WebButton8: TButton;
    procedure WebButton10Click(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure XDataWebConnection1Connect(Sender: TObject);
    procedure XDataWebConnection1Error(Error: TXDataWebConnectionError);
    procedure WebButton3Click(Sender: TObject);
    procedure WebButton4Click(Sender: TObject);
    procedure WebButton5Click(Sender: TObject);
    procedure WebButton6Click(Sender: TObject);
    procedure WebButton7Click(Sender: TObject);
    procedure WebButton8Click(Sender: TObject);
  private
    { Private declarations }

  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  Form1: TForm1;

implementation

{$R *.dfm}

uses Unit2, uUtility;

procedure TForm1.WebButton10Click(Sender: TObject);
begin
  lbl1.Caption:='hejsan';
  mmo1.Lines.Add('hejsan ' + DateTimeToStr(Now));
end;

procedure TForm1.WebButton1Click(Sender: TObject);
var
  newform:TForm2;

  procedure AfterShowModal(AValue:TModalResult);
  begin
    ShowMessage('Form 2 closed with new value: ' + newform.frm2Edit.text);
    WebEdit1.Text:=newform.frm2Edit.text;
  end;

  //async called OnCreate for TForm2
  procedure AfterCreate(AForm:TObject);
  begin
    (AForm as TForm2).frm2Edit.Text:=WebEdit1.Text;
  end;

begin
  newform:=TForm2.CreateNew(@AfterCreate);
  newform.Popup:=WebCheckBox1.Checked;
  newform.ShowModal(@AfterShowModal);
end;

procedure TForm1.WebButton2Click(Sender: TObject);
begin
  //XDataWebConnection1.Connected:=true;
  XDataWebConnection1.URL:='http://lslabtest.dyndns-server.com:8035/';
  XDataWebConnection1.OnConnect:= XDataWebConnection1Connect;
  XDataWebConnection1.OnError:= XDataWebConnection1Error;
  XDataWebConnection1.Connected:=true;
end;

procedure TForm1.WebButton3Click(Sender: TObject);

  procedure OnResult(Response:TXdataClientResponse);
  var GreetResult: string;
  begin
    GreetResult:=string(TJSObject(Response.Result)['value']);
    mmo1.Lines.Add(GreetResult);
  end;
begin
 // XDataWebClient1.RawInvoke('ICustomerService.GetCustomer', ['My name'],@OnResult);
  XDataWebClient1.RawInvoke('ICustomerService.EchoString', ['Magnus'],@OnResult);
end;


procedure TForm1.WebButton4Click(Sender: TObject);
  procedure OnResult(Response:TXdataClientResponse);
  var GreetResult: string;
  begin
    GreetResult:=string(TJSObject(Response.Result)['value']);
    mmo1.Lines.Add(GreetResult);
  end;
begin
  XDataWebClient1.RawInvoke('ICustomerService.Sum', [StrToFloat(WebEdit2.text),StrToFloat(WebEdit3.text)],@OnResult);
end;

procedure TForm1.WebButton5Click(Sender: TObject);
  //procedure OnResult(Response:TXdataClientResponse);
procedure OnResult(Response:TXdataClientResponse);
var
  JFDBS: TJSObject;
  JManager: TJSObject;
  JRowList: TJSArray;
  JTableList: TJSObject;

begin
  try
    mmo1.Lines.Add('A');
    JFDBS      := TJSObject(TJSObject(TJSJson.Parse(string(Response.Result)))['FDBS']);
    JManager   := TJSObject(JFDBS['Manager']);
    JTableList := TJSObject(TJSArray(JManager['TableList'])[0]);
    JRowList   := TJSArray(JTableList['RowList']);
    mmo1.Lines.Add('B' + string(Response.Result));

    XDataWebDataSet1.Close;
    XDataWebDataSet1.SetJSONData(JRowList);
mmo1.Lines.Add('C');
 //   XDataWebDataSet1.Open;
mmo1.Lines.Add('D');
//    XDataWebDataSet1.First;
//    mmo1.Lines.Add(XDataWebDataSet1.FieldByName('DESCRIPTION').AsString);
  except
    on Error: Exception do
    begin
      mmo1.Lines.Add('Error');
    end;
  end;
end;

begin
  XDataWebClient1.RawInvoke('ICustomerService.GetCustomer', ['FireDAC'],@OnResult);
end;


procedure TForm1.WebButton6Click(Sender: TObject);

var
  Result:integer;

  procedure OnResult(Response:TXdataClientResponse);
  var
    JFDBS:        TJSObject;
    JManager:     TJSObject;
    JTableList:   TJSObject;
    JColumnList:  TJSArray;
    JRowList:     TJSArray;

    StringFields:  Array of TStringField;
    IntegerFields: Array of TIntegerField;
    // Likely need a few more datatypes here

    i:integer;
//    Dataset: TXDataWebDataSet;

  begin
    Result:=-1;

    try
      mmo1.Lines.Add('A');
      JFDBS      := TJSObject(TJSObject(TJSJson.Parse(string(Response.Result)))['FDBS']);
      JManager   := TJSObject(JFDBS['Manager']);
      JTableList := TJSObject(TJSArray(JManager['TableList'])[0]);
      JColumnList:= TJSArray(JTableList['ColumnList']);
      JRowList   := TJSArray(JTableList['RowList']);
//      mmo1.Lines.Add('B' + string(Response.Result));

      for i := 0 to JRowList.Length - 1 do
      JRowList.Elements[i] := TJSObject(JRowList.Elements[i])['Original'];
      mmo1.Lines.Add('B ' + inttostr(JRowList.Length));
      mmo1.Lines.Add('C ' + inttostr(JColumnList.Length));

      // We're assuming Dataset parameter is newly created and empty.
      // First, add all the fields from JSON
      // NOTE: Very likely more datatypes need to be added here

      for i := 0 to JColumnList.Length-1 do
      begin
        if (String(TJSObject(JColumnList.Elements[i])['DataType']) = 'AnsiString') then
        begin

          // NOTE: Different datatypes may need different values set (eg: Size for strings)
          SetLength(StringFields, Length(StringFields) + 1);
          StringFields[Length(StringFields)-1] := TStringField.Create(XDataWebDataSet1);
//          StringFields[Length(StringFields)-1].FieldName := String(TJSObject(JColumnList.Elements[i])['Name']);
          StringFields[Length(StringFields)-1].Size      := Integer(TJSObject(JColumnList.Elements[i])['Size']);
//          StringFields[Length(StringFields)-1].Dataset   := Dataset;
          mmo1.Lines.Add('E ' + String(TJSObject(JColumnList.Elements[i])['Name']));


        end
        else if (String(TJSObject(JColumnList.Elements[i])['DataType']) = 'Int32') then
        begin
          SetLength(IntegerFields, Length(IntegerFields) + 1);
          IntegerFields[Length(IntegerFields)-1] := TIntegerField.Create(XDataWebDataSet1);
//          IntegerFields[Length(IntegerFields)-1].FieldName := String(TJSObject(JColumnList.Elements[i])['Name']);
//          IntegerFields[Length(IntegerFields)-1].Dataset   := Dataset;
          mmo1.Lines.Add('F ' + String(TJSObject(JColumnList.Elements[i])['Name']));
        end
        else
        begin
          mmo1.Lines.add('ERROR: Field ['+String(TJSObject(JColumnList.Elements[i])['Name'])+'] has an unexpected datatype ['+String(TJSObject(JColumnList.Elements[i])['DataType'])+']');
        end;
      end;

      // Add the data and return the dataset as opened
      XDataWebDataSet1.SetJSONData(JRowList);
      XDataWebDataSet1.Open;
      //Dataset.Open;
      Result:= XDataWebDataSet1.RecordCount;
      mmo1.Lines.Add('G: ' + IntToStr(Result));

    except
      on Error: Exception do
      begin
        mmo1.Lines.Add('Error: ' + Error.Message);
      end;

    end;
//    WebDBGrid1.Columns.Items[0].Width:=150;
//    WebDBGrid1.Columns.Items[1].Width:=150;
//    Options.ColumnSize.Stretch:=true;


  end;



begin
  XDataWebClient1.RawInvoke('ICustomerService.GetCustomer', ['FireDAC'],@OnResult);
end;

procedure TForm1.WebButton7Click(Sender: TObject);
begin
  WebStringGrid1.cells[1,1]:='Hej';
end;

procedure TForm1.WebButton8Click(Sender: TObject);
begin
  with webxlsx1 do begin
    grid:=webstringgrid1;
    gridstartcol:=0;
    gridstartrow:=0;
    save('MyFile.xlsx');
  end;
end;

procedure TForm1.XDataWebConnection1Connect(Sender: TObject);
begin
  mmo1.Lines.Add('connected');
end;

procedure TForm1.XDataWebConnection1Error(Error: TXDataWebConnectionError);
begin
  mmo1.Lines.Add('connection error');
  mmo1.Lines.add(Error.ErrorMessage);
end;


procedure TForm1.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbl1 := TLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  wbpnl1 := TPanel.Create(Self);
  WebButton10 := TButton.Create(Self);
  mmo1 := TMemo.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebCheckBox1 := TCheckBox.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebButton3 := TButton.Create(Self);
  WebButton4 := TButton.Create(Self);
  WebEdit2 := TEdit.Create(Self);
  WebEdit3 := TEdit.Create(Self);
  WebButton5 := TButton.Create(Self);
  WebButton6 := TButton.Create(Self);
  WebStringGrid1 := TStringGrid.Create(Self);
  WebButton7 := TButton.Create(Self);
  WebButton8 := TButton.Create(Self);
  XDataWebConnection1 := TXDataWebConnection.Create(Self);
  XDataWebClient1 := TXDataWebClient.Create(Self);
  XDataWebDataSet1 := TXDataWebDataSet.Create(Self);
  XDataWebDataSet1ID_TYPE_OF_RADIO := TIntegerField.Create(Self);
  XDataWebDataSet1DESCRIPTION := TStringField.Create(Self);
  WebDataSource1 := TDataSource.Create(Self);
  WebXLSX1 := TXLSX.Create(Self);

  lbl1.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  wbpnl1.BeforeLoadDFMValues;
  WebButton10.BeforeLoadDFMValues;
  mmo1.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebCheckBox1.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton3.BeforeLoadDFMValues;
  WebButton4.BeforeLoadDFMValues;
  WebEdit2.BeforeLoadDFMValues;
  WebEdit3.BeforeLoadDFMValues;
  WebButton5.BeforeLoadDFMValues;
  WebButton6.BeforeLoadDFMValues;
  WebStringGrid1.BeforeLoadDFMValues;
  WebButton7.BeforeLoadDFMValues;
  WebButton8.BeforeLoadDFMValues;
  XDataWebConnection1.BeforeLoadDFMValues;
  XDataWebClient1.BeforeLoadDFMValues;
  XDataWebDataSet1.BeforeLoadDFMValues;
  XDataWebDataSet1ID_TYPE_OF_RADIO.BeforeLoadDFMValues;
  XDataWebDataSet1DESCRIPTION.BeforeLoadDFMValues;
  WebDataSource1.BeforeLoadDFMValues;
  WebXLSX1.BeforeLoadDFMValues;
  try
    Name := 'Form1';
    Width := 709;
    Height := 626;
    Caption := 'Form1';
    Color := clSkyBlue;
    lbl1.SetParentComponent(Self);
    lbl1.Name := 'lbl1';
    lbl1.Left := 588;
    lbl1.Top := 538;
    lbl1.Width := 29;
    lbl1.Height := 25;
    lbl1.Caption := 'lbl1';
    lbl1.Color := clSkyBlue;
    lbl1.HeightPercent := 100.000000000000000000;
    lbl1.WidthPercent := 100.000000000000000000;
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 40;
    WebLabel1.Top := 32;
    WebLabel1.Width := 66;
    WebLabel1.Height := 25;
    WebLabel1.Caption := 'Form 1';
    WebLabel1.Color := clSkyBlue;
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clRed;
    WebLabel1.Font.Height := -21;
    WebLabel1.Font.Name := 'Tahoma';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    wbpnl1.SetParentComponent(Self);
    wbpnl1.Name := 'wbpnl1';
    wbpnl1.Left := 36;
    wbpnl1.Top := 324;
    wbpnl1.Width := 493;
    wbpnl1.Height := 13;
    wbpnl1.Caption := 'wbpnl1';
    wbpnl1.ChildOrder := 1;
    wbpnl1.Color := clYellow;
    wbpnl1.ShowCaption := False;
    wbpnl1.TabOrder := 0;
    WebButton10.SetParentComponent(Self);
    WebButton10.Name := 'WebButton10';
    WebButton10.Left := 565;
    WebButton10.Top := 482;
    WebButton10.Width := 117;
    WebButton10.Height := 33;
    WebButton10.Caption := 'Klicka p'#229' mig';
    WebButton10.ChildOrder := 1;
    WebButton10.HeightPercent := 100.000000000000000000;
    WebButton10.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton10, Self, 'OnClick', 'WebButton10Click');
    mmo1.SetParentComponent(Self);
    mmo1.Name := 'mmo1';
    mmo1.Left := 436;
    mmo1.Top := 343;
    mmo1.Width := 253;
    mmo1.Height := 128;
    mmo1.BorderStyle := bsNone;
    mmo1.HeightPercent := 100.000000000000000000;
    mmo1.Lines.BeginUpdate;
    try
      mmo1.Lines.Clear;
      mmo1.Lines.Add('mmo1');
    finally
      mmo1.Lines.EndUpdate;
    end;
    mmo1.SelLength := 0;
    mmo1.SelStart := 0;
    mmo1.WidthPercent := 100.000000000000000000;
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 36;
    WebButton1.Top := 156;
    WebButton1.Width := 185;
    WebButton1.Height := 53;
    WebButton1.Caption := 'Click here to open the second form';
    WebButton1.ChildOrder := 1;
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebCheckBox1.SetParentComponent(Self);
    WebCheckBox1.Name := 'WebCheckBox1';
    WebCheckBox1.Left := 40;
    WebCheckBox1.Top := 128;
    WebCheckBox1.Width := 197;
    WebCheckBox1.Height := 22;
    WebCheckBox1.Caption := 'Show form as popup';
    WebCheckBox1.ChildOrder := 5;
    WebCheckBox1.HeightPercent := 100.000000000000000000;
    WebCheckBox1.WidthPercent := 100.000000000000000000;
    WebEdit1.SetParentComponent(Self);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 40;
    WebEdit1.Top := 80;
    WebEdit1.Width := 181;
    WebEdit1.Height := 22;
    WebEdit1.ChildOrder := 6;
    WebEdit1.HeightPercent := 100.000000000000000000;
    WebEdit1.Text := 'sample text edit';
    WebEdit1.WidthPercent := 100.000000000000000000;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 36;
    WebPanel1.Top := 201;
    WebPanel1.Width := 629;
    WebPanel1.Height := 117;
    WebPanel1.Caption := 'WebPanel1';
    WebPanel1.ChildOrder := 7;
    WebPanel1.ShowCaption := False;
    WebPanel1.TabOrder := 6;
    WebLabel2.SetParentComponent(WebPanel1);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 24;
    WebLabel2.Top := 12;
    WebLabel2.Width := 349;
    WebLabel2.Height := 100;
    WebLabel2.Caption := 'Web4 Magnus Demo showing a web application consisting of two form files and showing the form either on top of the main form or launching it as a new web page';
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.WordWrap := True;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 500;
    WebButton2.Top := 16;
    WebButton2.Width := 96;
    WebButton2.Height := 25;
    WebButton2.Caption := 'Connect';
    WebButton2.ChildOrder := 9;
    WebButton2.HeightPercent := 100.000000000000000000;
    WebButton2.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebButton3.SetParentComponent(Self);
    WebButton3.Name := 'WebButton3';
    WebButton3.Left := 500;
    WebButton3.Top := 52;
    WebButton3.Width := 96;
    WebButton3.Height := 25;
    WebButton3.Caption := 'echo Magnus';
    WebButton3.ChildOrder := 9;
    WebButton3.HeightPercent := 100.000000000000000000;
    WebButton3.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton3, Self, 'OnClick', 'WebButton3Click');
    WebButton4.SetParentComponent(Self);
    WebButton4.Name := 'WebButton4';
    WebButton4.Left := 500;
    WebButton4.Top := 88;
    WebButton4.Width := 96;
    WebButton4.Height := 25;
    WebButton4.Caption := 'Sum';
    WebButton4.ChildOrder := 9;
    WebButton4.HeightPercent := 100.000000000000000000;
    WebButton4.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton4, Self, 'OnClick', 'WebButton4Click');
    WebEdit2.SetParentComponent(Self);
    WebEdit2.Name := 'WebEdit2';
    WebEdit2.Left := 605;
    WebEdit2.Top := 90;
    WebEdit2.Width := 33;
    WebEdit2.Height := 22;
    WebEdit2.ChildOrder := 12;
    WebEdit2.HeightPercent := 100.000000000000000000;
    WebEdit2.Text := '2';
    WebEdit2.WidthPercent := 100.000000000000000000;
    WebEdit3.SetParentComponent(Self);
    WebEdit3.Name := 'WebEdit3';
    WebEdit3.Left := 656;
    WebEdit3.Top := 90;
    WebEdit3.Width := 33;
    WebEdit3.Height := 22;
    WebEdit3.ChildOrder := 12;
    WebEdit3.HeightPercent := 100.000000000000000000;
    WebEdit3.Text := '3';
    WebEdit3.WidthPercent := 100.000000000000000000;
    WebButton5.SetParentComponent(Self);
    WebButton5.Name := 'WebButton5';
    WebButton5.Left := 500;
    WebButton5.Top := 126;
    WebButton5.Width := 96;
    WebButton5.Height := 25;
    WebButton5.Caption := 'JSON Customers';
    WebButton5.ChildOrder := 9;
    WebButton5.HeightPercent := 100.000000000000000000;
    WebButton5.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton5, Self, 'OnClick', 'WebButton5Click');
    WebButton6.SetParentComponent(Self);
    WebButton6.Name := 'WebButton6';
    WebButton6.Left := 500;
    WebButton6.Top := 170;
    WebButton6.Width := 96;
    WebButton6.Height := 25;
    WebButton6.Caption := 'JSON Customers2';
    WebButton6.ChildOrder := 9;
    WebButton6.HeightPercent := 100.000000000000000000;
    WebButton6.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton6, Self, 'OnClick', 'WebButton6Click');
    WebStringGrid1.SetParentComponent(Self);
    WebStringGrid1.Name := 'WebStringGrid1';
    WebStringGrid1.Left := 36;
    WebStringGrid1.Top := 350;
    WebStringGrid1.Width := 358;
    WebStringGrid1.Height := 165;
    WebStringGrid1.Color := clWhite;
    WebStringGrid1.TabOrder := 14;
    WebStringGrid1.FixedFont.Charset := DEFAULT_CHARSET;
    WebStringGrid1.FixedFont.Color := clWindowText;
    WebStringGrid1.FixedFont.Height := -18;
    WebStringGrid1.FixedFont.Name := 'Segoe UI';
    WebStringGrid1.FixedFont.Style := [];
    WebStringGrid1.RangeEdit.Max := 100.000000000000000000;
    WebStringGrid1.RangeEdit.Step := 1.000000000000000000;
    WebStringGrid1.HeightPercent := 100.000000000000000000;
    WebStringGrid1.WidthPercent := 100.000000000000000000;
    WebButton7.SetParentComponent(Self);
    WebButton7.Name := 'WebButton7';
    WebButton7.Left := 60;
    WebButton7.Top := 539;
    WebButton7.Width := 96;
    WebButton7.Height := 25;
    WebButton7.Caption := 'WebButton7';
    WebButton7.ChildOrder := 17;
    WebButton7.HeightPercent := 100.000000000000000000;
    WebButton7.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton7, Self, 'OnClick', 'WebButton7Click');
    WebButton8.SetParentComponent(Self);
    WebButton8.Name := 'WebButton8';
    WebButton8.Left := 240;
    WebButton8.Top := 538;
    WebButton8.Width := 96;
    WebButton8.Height := 25;
    WebButton8.Caption := 'WebButton8';
    WebButton8.ChildOrder := 18;
    WebButton8.HeightPercent := 100.000000000000000000;
    WebButton8.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton8, Self, 'OnClick', 'WebButton8Click');
    XDataWebConnection1.SetParentComponent(Self);
    XDataWebConnection1.Name := 'XDataWebConnection1';
    XDataWebConnection1.URL := 'http://lslabtest.dyndns-server.com:8035/';
    SetEvent(XDataWebConnection1, Self, 'OnConnect', 'XDataWebConnection1Connect');
    SetEvent(XDataWebConnection1, Self, 'OnError', 'XDataWebConnection1Error');
    XDataWebConnection1.Left := 244;
    XDataWebConnection1.Top := 20;
    XDataWebClient1.SetParentComponent(Self);
    XDataWebClient1.Name := 'XDataWebClient1';
    XDataWebClient1.Connection := XDataWebConnection1;
    XDataWebClient1.Left := 288;
    XDataWebClient1.Top := 132;
    XDataWebDataSet1.SetParentComponent(Self);
    XDataWebDataSet1.Name := 'XDataWebDataSet1';
    XDataWebDataSet1.Connection := XDataWebConnection1;
    XDataWebDataSet1.Left := 340;
    XDataWebDataSet1.Top := 72;
    XDataWebDataSet1ID_TYPE_OF_RADIO.SetParentComponent(XDataWebDataSet1);
    XDataWebDataSet1ID_TYPE_OF_RADIO.Name := 'XDataWebDataSet1ID_TYPE_OF_RADIO';
    XDataWebDataSet1ID_TYPE_OF_RADIO.FieldName := 'ID_TYPE_OF_RADIO';
    XDataWebDataSet1DESCRIPTION.SetParentComponent(XDataWebDataSet1);
    XDataWebDataSet1DESCRIPTION.Name := 'XDataWebDataSet1DESCRIPTION';
    XDataWebDataSet1DESCRIPTION.FieldName := 'DESCRIPTION';
    WebDataSource1.SetParentComponent(Self);
    WebDataSource1.Name := 'WebDataSource1';
    WebDataSource1.DataSet := XDataWebDataSet1;
    WebDataSource1.Left := 432;
    WebDataSource1.Top := 20;
    WebXLSX1.SetParentComponent(Self);
    WebXLSX1.Name := 'WebXLSX1';
    WebXLSX1.Grid := WebStringGrid1;
    WebXLSX1.Left := 436;
    WebXLSX1.Top := 492;
  finally
    lbl1.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    wbpnl1.AfterLoadDFMValues;
    WebButton10.AfterLoadDFMValues;
    mmo1.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebCheckBox1.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton3.AfterLoadDFMValues;
    WebButton4.AfterLoadDFMValues;
    WebEdit2.AfterLoadDFMValues;
    WebEdit3.AfterLoadDFMValues;
    WebButton5.AfterLoadDFMValues;
    WebButton6.AfterLoadDFMValues;
    WebStringGrid1.AfterLoadDFMValues;
    WebButton7.AfterLoadDFMValues;
    WebButton8.AfterLoadDFMValues;
    XDataWebConnection1.AfterLoadDFMValues;
    XDataWebClient1.AfterLoadDFMValues;
    XDataWebDataSet1.AfterLoadDFMValues;
    XDataWebDataSet1ID_TYPE_OF_RADIO.AfterLoadDFMValues;
    XDataWebDataSet1DESCRIPTION.AfterLoadDFMValues;
    WebDataSource1.AfterLoadDFMValues;
    WebXLSX1.AfterLoadDFMValues;
  end;
end;

end.
